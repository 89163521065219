<template>
    <div class="container-interior">
        <div v-for="(item,i) in items" :key="'item-'+i+'-'+item.id" class="card-interior">
            <a :href="'/portfolio/'+item.id">            
                <img :src="item.image" :alt="item.customer+' More Custom Interior'">
                <div class="overlay-interior active">
                    <div class="content-interior">
                        <div class="label-interior">
                            <div>
                                <span class="portfolio-desain">{{ item.category }}</span>
                                <span class="portfolio-desain">{{ item.design }}</span>
                            </div>
                            <div class="portfolio-label">
                                {{ item.customer }}
                            </div>
                            <div class="portfolio-location">
                                <i class="fa fa-map-marker"></i> {{ item.location }}
                            </div>
                        </div>
                        <a><i class="fa fa-chevron-right"></i></a>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name:"ListCustomer",
    props:["items"],
}
</script>

<style>

</style>