<template>
    <div class="ol-body" style="margin-top:0px;background:#ffffff;">
        <div class="back-home">
            <a href="/"><i class="fa fa-arrow-left"></i> &nbsp; Kembali Belanja</a>
        </div>
        <header>
            <div class="header-content">
                <div class="logo-interior">
                    <a href="/desain-interior">
                        <img src="https://cdn.morefurniture.id/MORE/asset/morelogo_nd.png" alt="Logo More Furniture">
                    </a>
                </div>
                <nav class="menu-interior">
                    <ul>
                        <li><a href="/desain-interior#ruangan" class="scrollLink">Ruangan</a></li>
                        <li><a href="/desain-interior#proyek" class="scrollLink">Proyek</a></li>
                        <li><a href="/desain-interior#testimoni" class="scrollLink">Testimoni</a></li>
                        <!-- <li><a href="interior#faq" class="scrollLink">FAQ</a></li> -->
                    </ul>
                </nav>
                <div class="consultation-button">
                    <a href="http://bit.ly/more-custom" target="_blank">Konsultasi Sekarang</a>
                </div>
                <div class="burger-menu" @click="toggleMenu">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </div>
        </header>
        <div class="mobile-menu" id="mobileMenu">
            <ul>
                <li><a href="/desain-interior#ruangan" class="scrollLink">Ruangan</a></li>
                <li><a href="/desain-interior#proyek" class="scrollLink">Proyek</a></li>
                <li><a href="/desain-interior#testimoni" class="scrollLink">Testimoni</a></li>
                <!-- <li><a href="interior#faq" class="scrollLink">FAQ</a></li> -->
            </ul>
        </div>
        <div style="margin-top:120px;"></div>
        
        <div class="ol-wrapper ol-wrapper--large margin-top" id="proyek">
            <div class="row no-gutters" >
                <div class="col-12">
                    <div class="title-div-content">
                        <h2 class="heading-content">Kategori {{ this.category }}</h2>
                        <span class="sub-heading-content">Koleksi Hasil Desain Interior</span>
                    </div>
                </div>
            </div>
            <ListCustomer :items="items" /> 
        </div>
        <div class="padding-usp-interior">
            <div class="ol-wrapper ol-wrapper--large" >
                <div class="title-div-content">
                    <h2 class="heading-content">Keunggulan More Custom</h2>
                    <span class="sub-heading-content">Nikmati Banyak Keunggulan Jasa Custom Interior MORE dengan Hasil yang Terbaik!</span>
                </div>
                <div class="row no-gutters" style="margin-top:32px;font-weight: 700;font-size: 12px;color: #141414;"> 
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/konsultasi.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Gratis <br>Interior</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/desain.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Desain Interior<br> Milenial</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/garansi.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Garansi <br>2 Tahun</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/transparan.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Harga<br> Transparan</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/cicilan.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Cicilan<br> 0%</div>
                    </div>
                    <div class="col-4 col-lg-2"> 
                        <div style="margin-top:8px;"><img :src="require('@/assets/images/interior/tim.png')"  width="50" height="50" /></div>
                        <div style="margin-top:8px;">Tim <br>Berpengalaman</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="ol-wrapper ol-wrapper--large margin-top" >
            <div class="row no-gutters" style="background: #F6F7F9;">
            <div class="col-md-12 col-lg-6">
                <div class="content-location">
                    <div class="content-location-detail">
                        <h4 style="font-weight: 800;line-height: 34px;color: #141414;">Lebih Dekat dengan MORE Custom Interior!</h4>
                        <div class="content-location-info">
                            <table>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/location.png')"  width="16" height="16" style="vertical-align: top;"/></td>
                                <td style="padding:4px;">Jl. Mayjen HR. Muhammad No.33, Pradahkalikendal, Kec. Dukuhpakis, Surabaya, Jawa Timur 60226</td>
                            </tr>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/time.png')"  width="16" height="16" /></td>
                                <td style="padding:4px;">09:00 - 21:00</td>
                            </tr>
                            <tr>
                                <td style="padding:4px;"><img :src="require('@/assets/images/interior/phone.png')"  width="16" height="16" /></td>
                                <td style="padding:4px;">+62 811 306 6171</td>
                            </tr>
                            </table>
                        </div>
                        <div style="margin-top:16px;margin-bottom:16px;">
                            <a class="content-location-button-1" href="https://www.instagram.com/moreinterior.id/" target="_blank">Ikuti Kami di Instagram</a>
                            <a class="content-location-button-2" href="https://maps.app.goo.gl/y8WwqUPFLWdjVzTg6" target="_blank">Menuju Lokasi</a>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <img :src="require('@/assets/images/interior/Facade-More-521x316.jpg')"  width="100%" height="100%" />
            </div>
            </div>
        </div>
        <div class="break-24 retail-dekstop"></div>
        <div class="break-24 retail-dekstop"></div>
        <div class="break-24 retail-dekstop"></div>
    </div>    
</template>

<script>
import globalUrl from '@/util/globallUrl'
import ListCustomer from "@/components/interior/ListCustomer.vue"

const URL = globalUrl.WS_KONSULTASI;
import { mapGetters,mapActions } from 'vuex'
export default {
    name: "InteriorCustomer",
    components:{
        ListCustomer
    },
    data(){
        return {
            category:"",
            statusReset:false,
            filteredBy:this.$store.state.product.list.filtered,
            loading:true,
            list:{
                more:false,
                total:0,
            },
        }
    },
    computed:{
        ...mapGetters('auth', ['location','authenticated']),
        ...mapGetters("interior",["items","filtered"]),
    },
    methods:{
        ...mapActions("interior",["fetchItems"]),
        scrollToHash(hash) {
            hash = hash.replace('#', '');
                // const h = document.getElementsByTagName('header')[0].offsetHeight + document.getElementById('header-promotion').offsetHeight;
                const el = document.getElementById(hash);
                if(el) {
                    var top = el.getBoundingClientRect().top + window.pageYOffset;
                    top= top-110;
                    console.log(top);
                    window.scrollTo({top: top, behavior: 'smooth'});
                    this.$router.push({hash: hash});
            }
        },
        
        toggleMenu() {
            var menu = document.getElementById("mobileMenu");
            menu.classList.toggle("show");
        },
        resetProgress(itemId) {
            const progressInner = document.getElementById(`progress-inner${this.steps.findIndex(item => item.id === itemId) + 1}`);
            progressInner.style.width = '0';
            progressInner.style.transition = 'none';
        },
        animateProgress(index) {
            const progressInner = document.getElementById(`progress-inner${index + 1}`);
            progressInner.style.transition = 'width 5s linear';
            progressInner.style.width = '100%';
        },
        handleClick(index) {
            this.currentIndex = index;
            this.activateItem(index);
        },
        async getItems(firstPage = false,per_page=null){
            this.loading = true;
            if(firstPage) this.filteredBy.page = 1;            
            if(per_page != null) this.filteredBy.per_page = per_page;
            this.filteredBy.category = this.$route.params.slug;
            // if(this.copiedFilter && this.$route.params.filter) this.retrieveCopiedFilter();
            // this.retrieveCopiedFilter();
            
            this.$store.commit("interior/setFiltered",this.filteredBy);
            
            const results = await this.fetchItems();
            
            this.statusReset = false;
            this.loading = false;
            if(results.success){
                this.list.total = results.data.total; 
                this.list.more = results.data.pagination.more;
                if(this.list.more) {
                    this.filteredBy.page=this.filteredBy.page+1;
                    this.$store.commit("interior/setFiltered",{page:this.filteredBy.page});
                }
            }else{
                this.$toast.open({ message: "Gagal mendapatkan data pelanggan", type: "error", duration: 3000, })
            }
        },
        retrieveCopiedFilter(){
            this.statusReset = true;
            let query = _.mapKeys(queryString.parse(this.$route.params.filter), (value, key) => key.replace("[]", "") );

            query.per_page = this.filteredBy.per_page;
            query.page = 1;
            
            this.filteredBy = query;
            
            this.$store.commit("interior/setCopiedFilter", false);
            
        },
    },
    async mounted(){
        await Promise.all([
            this.getItems(true),
            
        ]);

        var slug = this.$route.params.slug;
        let result = slug.replace("-", " ");
        const words = result.split(" ");

        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        }
        this.category = words.join(" ");
    },
    watch:{
        $route(to, from) {
            this.init();
        },
    },
    updated(){
        
    },
};
</script>

<style lang="css" scoped>
@import '../../assets/css/bootstrap.css';
@import '../../assets/css/interior.css';
</style>
